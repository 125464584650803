exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-cookies-policy-tsx": () => import("./../../../src/templates/cookies-policy.tsx" /* webpackChunkName: "component---src-templates-cookies-policy-tsx" */),
  "component---src-templates-dashboard-tsx": () => import("./../../../src/templates/dashboard.tsx" /* webpackChunkName: "component---src-templates-dashboard-tsx" */),
  "component---src-templates-faqs-tsx": () => import("./../../../src/templates/faqs.tsx" /* webpackChunkName: "component---src-templates-faqs-tsx" */),
  "component---src-templates-guide-tsx": () => import("./../../../src/templates/guide.tsx" /* webpackChunkName: "component---src-templates-guide-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-jewel-tsx": () => import("./../../../src/templates/jewel.tsx" /* webpackChunkName: "component---src-templates-jewel-tsx" */),
  "component---src-templates-lite-faqs-tsx": () => import("./../../../src/templates/lite/faqs.tsx" /* webpackChunkName: "component---src-templates-lite-faqs-tsx" */),
  "component---src-templates-lite-guide-tsx": () => import("./../../../src/templates/lite/guide.tsx" /* webpackChunkName: "component---src-templates-lite-guide-tsx" */),
  "component---src-templates-lite-team-tsx": () => import("./../../../src/templates/lite/team.tsx" /* webpackChunkName: "component---src-templates-lite-team-tsx" */),
  "component---src-templates-lite-tsx": () => import("./../../../src/templates/lite.tsx" /* webpackChunkName: "component---src-templates-lite-tsx" */),
  "component---src-templates-lite-where-to-trade-tsx": () => import("./../../../src/templates/lite/where-to-trade.tsx" /* webpackChunkName: "component---src-templates-lite-where-to-trade-tsx" */),
  "component---src-templates-mint-tsx": () => import("./../../../src/templates/mint.tsx" /* webpackChunkName: "component---src-templates-mint-tsx" */),
  "component---src-templates-nft-tsx": () => import("./../../../src/templates/nft.tsx" /* webpackChunkName: "component---src-templates-nft-tsx" */),
  "component---src-templates-partners-tsx": () => import("./../../../src/templates/partners.tsx" /* webpackChunkName: "component---src-templates-partners-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-privacy-policy-tsx": () => import("./../../../src/templates/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-tsx" */),
  "component---src-templates-roadmap-tsx": () => import("./../../../src/templates/roadmap.tsx" /* webpackChunkName: "component---src-templates-roadmap-tsx" */),
  "component---src-templates-rsi-div-tsx": () => import("./../../../src/templates/rsi-div.tsx" /* webpackChunkName: "component---src-templates-rsi-div-tsx" */),
  "component---src-templates-service-not-available-tsx": () => import("./../../../src/templates/service-not-available.tsx" /* webpackChunkName: "component---src-templates-service-not-available-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */),
  "component---src-templates-tos-tsx": () => import("./../../../src/templates/tos.tsx" /* webpackChunkName: "component---src-templates-tos-tsx" */),
  "component---src-templates-traders-tsx": () => import("./../../../src/templates/traders.tsx" /* webpackChunkName: "component---src-templates-traders-tsx" */),
  "component---src-templates-volume-alerts-tsx": () => import("./../../../src/templates/volume-alerts.tsx" /* webpackChunkName: "component---src-templates-volume-alerts-tsx" */),
  "component---src-templates-welcome-mafioso-tsx": () => import("./../../../src/templates/welcome-mafioso.tsx" /* webpackChunkName: "component---src-templates-welcome-mafioso-tsx" */),
  "component---src-templates-welcome-mogul-tsx": () => import("./../../../src/templates/welcome-mogul.tsx" /* webpackChunkName: "component---src-templates-welcome-mogul-tsx" */),
  "component---src-templates-where-to-trade-tsx": () => import("./../../../src/templates/where-to-trade.tsx" /* webpackChunkName: "component---src-templates-where-to-trade-tsx" */)
}

